<template>
    <div id="content" class="product">
        <template v-if="form">
            <Form :id="productID" @back="closeForm"></Form>
        </template>
        <template v-else-if="exportPDF">
            <ExportProduct @back="closeExportProduct"></ExportProduct>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <div>
                    <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="addProduct" bLabel="product.addButton"></Button>
                    <Button bType="more" :bCallback="showMoreOptions"></Button>
                    <div class="more-options" v-if="moreOptions">
                        <Button id="more-download-btn" bType="print" :bCallback="doPrint" bLabel="nvgt.report.print_product_list"></Button>
                    </div>
                </div>
            </div>

            <div class="product-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterProducts
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length === 0"
                        @resetPageDatatable="top(true)"
                        :toolTypeAvaible="toolTypeAvaible"
                    ></FilterProducts>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by="name"
                        :sort-asc="true"
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <template #item.code="item">
                            <span> {{ item.item.code }}</span>
                        </template>
                        <template #item.image="item">
                            <!-- <div class="images" v-if="item.item.image != 'false' && item.item.image != false" @click="goToReport(item.item.id)" :style="{ backgroundImage: 'url(' + item.item.image + ')' }"></div>
                            <div v-else class="image-default"></div> -->

                            <div class="images">
                                <cld-image
                                    v-if="item.item.image != 'false' && item.item.image != false"
                                    class="images"
                                    :publicId="item.item.image"
                                    type="fetch"
                                    loading="lazy"
                                >
                                    <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                </cld-image>
                                <div v-else class="image-default"></div>
                            </div>
                        </template>
                        <template #item.name="item">
                            <div @click="goToReport(item.item.id)">
                                <span class>{{ item.item.name }}</span>
                            </div>
                        </template>
                        <template #item.category_id="item">
                            <div @click="goToReport(item.item.id)">
                                <span class v-if="getCategory(item.item.category_id)">{{ getCategory(item.item.category_id).name }}</span>
                            </div>
                        </template>
                        <template #item.states="item">
                            <div @click="goToReport(item.item.id)">
                                <Tags :tags="getState(item.item.states)" :nameTag="'name'" value="id"></Tags>
                            </div>
                        </template>
                        <template #item.translations="item" v-if="organizationRender.availableLangs">
                            <div @click="goToReport(item.item.id)">
                                <template v-if="!getTranslations(item.item.translations)">
                                    <!-- {{ $t('multi-language.no_translated') }} -->
                                </template>
                                <template v-else>
                                    <Tags :tags="getTranslations(item.item.translations)" :nameTag="'name'" value="name" :backColor="'backColor'"></Tags>
                                </template>
                            </div>
                        </template>
                        <template #item.locations="item">
                            <div @click="goToReport(item.item.id)">
                                <Tags
                                    v-if="Object.values(item.item.locations).length > 0"
                                    :backImage="'location_inactive_s30.svg'"
                                    :tags="Object.values(item.item.locations)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <Tags
                                    v-else-if="Object.values(item.item.groups).length > 0"
                                    :backImage="'menu_inactive_s30.svg'"
                                    :tags="Object.values(item.item.groups)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <div class="all-locations" v-else>{{ $t('product.all_available') }}</div>
                            </div>
                        </template>
                        <template #item.color="item">
                            <div @click="goToReport(item.item.id)">
                                <div class="ball" :style="[item.item.color ? { backgroundColor: item.item.color } : { backgroundColor: '#B6B9BE' }]"></div>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <Table-statuses
                                    :text="item.item.status == '1' ? $t('sites.form.active') : item.item.status == '-2' ? $t('sites.form.inactive') : ''"
                                    :status="item.item.status == '1' ? 'active' : 'inactive'"
                                >
                                </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && Object.values(items).length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="addProduct"
                            :buttonText="$t('product.addButton')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.products')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FilterProducts from '@/components/domain/product/filter'
import Form from '@/components/domain/product/form'
import Tags from '@/components/ui/Tags'
import EmptyTable from '@/components/ui/EmptyTable'
import TableStatuses from '@/components/ui/TableStatuses.vue'
import ExportProduct from '@/components/domain/product/exportProduct'

export default {
    components: {
        FilterProducts,
        Form,
        Tags,
        EmptyTable,
        TableStatuses,
        ExportProduct
    },
    name: 'Product',
    data() {
        return {
            moreOptions: false,
            noChanges: false,
            productID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('product.code_title'),
                    align: 'start',
                    sortable: true,
                    value: 'code',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.icon'),
                    align: 'start',
                    sortable: false,
                    value: 'image',
                    width: '120px',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.category'),
                    align: 'start',
                    sortable: true,
                    width: '220px',
                    value: 'category_id',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.assigned_states'),
                    value: 'states',
                    align: 'start',
                    width: '300px',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.sites'),
                    value: 'locations',
                    align: 'start',
                    width: '300px',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.status'),
                    value: 'status',
                    align: 'start',
                    width: '150px',
                    class: 'header-table'
                }
            ],
            exportPDF: false,
            filtersSidebarIsHidden: false,
            selected: [],
            types: {
                1: {
                    name: i18n.t('timers_product.label')
                },
                2: {
                    name: i18n.t('timers_product.digitalTimer')
                },
                3: {
                    name: i18n.t('timers_product.both')
                }
            }
        }
    },
    computed: {
        userRole() {
            const user = this.$store.getters['login/getLocalEmployee']
            const roles = this.$store.getters['employee/getRol'](user.rol)

            if (roles != undefined) {
                return parseInt(roles.level)
            }
            return false
        },
        toolTypeAvaible() {
            return this.$store.state.login.config.tools.includes(16)
        },
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            var result = { ...this.$store.getters['label/getSortedLabels'] }
            if (result) {
                return result
            }
            return false
        },
        itemsLoaded() {
            return this.$store.getters['label/getItemsProducts']
        },
        hasAccess() {
            return this.checkPermission('/product/add')
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },

        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                // var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        }
    },
    methods: {
        setTypeStateText(state) {
            let stateType = ''
            if (state === '1') {
                stateType = i18n.t('timers_product.label')
            } else if (state === '2') {
                stateType = i18n.t('timers_product.digitalTimer')
            } else {
                stateType = i18n.t('timers_product.both')
            }
            return stateType.trim()
        },
        setTypeState(state) {
            let stateType = []
            if (state === '1') {
                stateType.label = true
                stateType.timer = false
            } else if (state === '2') {
                stateType.label = false
                stateType.timer = true
            } else {
                stateType.label = true
                stateType.timer = true
            }
            return stateType
        },
        getActiveClasses(states) {
            let labelActive = false
            let timerActive = false

            Object.values(states).forEach((state) => {
                if (state.type === '1') {
                    labelActive = true
                } else if (state.type === '2') {
                    timerActive = true
                } else if (state.type === '3') {
                    labelActive = true
                    timerActive = true
                }
            })

            return { labelActive, timerActive }
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        getCategory(id) {
            if (id) {
                var result = this.$store.getters['label/getAllCategories'](id)
                if (result) {
                    return result
                }
            } else {
                return false
            }
        },

        getTranslations(oTranslations) {
            var self = this
            if (oTranslations && Object.keys(oTranslations).length > 0) {
                return Object.keys(oTranslations).map(function (key) {
                    if (self.organizationRender.availableLangs.includes(key)) {
                        return {
                            name:
                                self.organizationRender.allLangs[key].name +
                                ' <b>(' +
                                parseInt((oTranslations[key].translated / (oTranslations[key].missing + oTranslations[key].translated)) * 100) +
                                '%)</b>',
                            backColor: oTranslations[key].missing > 0 ? '#f5d9bc' : '#c2f3db'
                        }
                    }
                })
            } else return false
        },

        getState(states) {
            var result = []
            if (states) {
                for (var state in states) {
                    var idx = states[state]
                    if (idx.state) {
                        var res = false
                        res = this.$store.getters['label/getAllStates'](idx.state)
                        if (res) {
                            //Variables necesarias
                            //Variables html para saltos de linea y negrita
                            var boldTextOpen = '<b>'
                            var boldTextClose = '</b>'
                            var lineBreak = '<br>'

                            //Formato que tendrá el tiempo de preparación en el TAG
                            var formatTimePrepTag = false
                            //Formato que tendrá el tiempo de preparación en el tooltip del TAG
                            var formatPrepTimeTagTooltip = false
                            //Formato que tendrá el tiempo de caducidad en el TAG
                            var formatTimeTag = false
                            //Formato que tendrá el tiempo de caducidad en el tooltip del TAG
                            var formatTimeTagTooltip = false
                            //Saber si tiene proximo estado y nombrarlo
                            var nextState = false
                            //Formato que tendrá el proximo estado en el tooltip del TAG
                            var formatTooltipNextState = false
                            var formatNote = false
                            var note = false

                            // Si tiene preptime le damos el formato que queremos tanto al tag como al tooltip del tag
                            if (idx.preptime != 0) {
                                // En negrita tiempo de preparación que se verá en TAG
                                formatTimePrepTag = boldTextOpen + '(' + this.formatMinutesToMax(idx.preptime, 'state') + ' / '

                                formatPrepTimeTagTooltip =
                                    boldTextOpen + this.$t('product.time_prep') + boldTextClose + lineBreak + this.formatMinutesToMax(idx.preptime)
                            }
                            // Si tiene tiempo de caducidad le damos el formato que queremos tanto al tag como al tooltip del tag
                            if (idx.time != 0) {
                                formatTimeTag = this.formatMinutesToMax(idx.time, 'state')
                                formatTimeTagTooltip =
                                    boldTextOpen +
                                    this.$t('product.tprep_desc') +
                                    boldTextClose +
                                    lineBreak +
                                    this.formatMinutesToMax(idx.time) +
                                    lineBreak +
                                    this.setTypeStateText(states[state].type)
                            }

                            // Si tiene proximo estado le damos el formato que queremos tanto al tag como al tooltip del tag
                            if (idx.next_state) {
                                nextState = this.$store.getters['label/getStatesForm'](idx.next_state)
                                if (nextState) {
                                    nextState = nextState.name
                                }
                                formatTooltipNextState = boldTextOpen + this.$t('product.form.next_state') + boldTextClose + lineBreak + nextState
                            }
                            // Si tiene anotación le damos el formato que queremos tanto al tag como al tooltip del tag
                            if (idx.msg) {
                                note = idx.msg
                                formatNote = boldTextOpen + this.$t('product.annotation') + boldTextClose + lineBreak + note
                            }

                            //Aquí se hace la logica para poder añadir el array a los tags con todos los formatos correctos

                            var resultWithoutTimePrep = boldTextOpen + '(' + formatTimeTag + ')' + boldTextClose
                            var resultWithTimePrep = boldTextOpen + formatTimePrepTag + formatTimeTag + ')' + boldTextClose
                            if (res.name.length > 25) {
                                res.name = res.name.slice(0, 25) + '...'
                            }
                            // console.log(res.name)
                            let type_state = this.setTypeState(states[state].type)
                            const timer_state = type_state && type_state.timer ? 'active' : ''
                            const label_state = type_state && type_state.label ? 'active' : ''
                            let type = ''
                            if (this.toolTypeAvaible) {
                                type = `<div class="type"><div class="timer-tag ${timer_state}"></div> <div class="label-tag ${label_state}"></div></div>`
                            }

                            result.push({
                                //Nombre del estado
                                id: res.id,
                                //Formato del tag
                                name:
                                    res.name +
                                    (formatTimePrepTag && formatTimeTag
                                        ? resultWithTimePrep
                                        : !formatTimePrepTag && formatTimeTag
                                        ? resultWithoutTimePrep
                                        : '') +
                                    type,
                                //Formato del  tooltip del tag
                                tooltipMsg:
                                    (formatPrepTimeTagTooltip ? formatPrepTimeTagTooltip : '') +
                                    (formatTimeTagTooltip && formatPrepTimeTagTooltip
                                        ? lineBreak + lineBreak + formatTimeTagTooltip
                                        : formatTimeTagTooltip
                                        ? formatTimeTagTooltip
                                        : '') +
                                    (formatTooltipNextState && formatTimeTagTooltip
                                        ? lineBreak + lineBreak + formatTooltipNextState
                                        : formatTooltipNextState
                                        ? formatTooltipNextState
                                        : '') +
                                    (formatTooltipNextState && note ? lineBreak + lineBreak + formatNote : formatNote ? formatNote : '')
                            })
                        }
                    }
                }
                return result
            } else {
                return false
            }
        },
        formatMinutesToMax(val, formatF) {
            val = parseFloat(val)
            var negative = false
            if (isNaN(val)) val = 0
            if (val < 0) {
                val = Math.abs(val)
                negative = true
            }
            var years, months, days, hours, minutes

            if (val >= 525600) {
                years = Math.floor(val / 525600)
                val = val % 525600
            }

            if (val >= 43200) {
                months = Math.floor(val / 43200)
                val = val % 43200
            }
            if (val >= 1440) {
                days = Math.floor(val / 1440)
                val = val % 1440
            }
            if (val >= 60) {
                hours = Math.floor(val / 60)
                val = val % 60
            }

            minutes = val
            var result = ''
            if (negative) {
                result = '-'
            }

            if (formatF == 'state') {
                result +=
                    (years > 0 ? years + this.$t('assets.timing.year_initials') : '') +
                    ' ' +
                    (months > 0 ? months + this.$t('assets.timing.month_initials') : '') +
                    ' ' +
                    (days > 0 ? days + this.$t('assets.timing.day_initials') : '') +
                    ' ' +
                    (hours > 0 ? hours + this.$t('assets.timing.hour_initials') : '') +
                    ' ' +
                    (minutes > 0 ? ' ' + minutes + "'" : '')
            } else {
                result +=
                    (years > 0 ? years + ' ' + this.$tc('assets.timing.year', years) : '') +
                    ' ' +
                    (months > 0 ? months + ' ' + this.$tc('assets.timing.month', months) : '') +
                    ' ' +
                    (days > 0 ? days + ' ' + this.$tc('assets.timing.day', days) : '') +
                    ' ' +
                    (hours > 0 ? hours + ' ' + this.$tc('assets.timing.hour', hours) : '') +
                    ' ' +
                    (minutes > 0 ? ' ' + minutes + ' ' + this.$tc('assets.timing.minute', minutes) : '')
            }
            if (negative) {
                result = result.replace('-  ', '-')
                result = result.replace('- ', '-')
            }

            return result
        },

        goStates() {
            this.$router.push({ name: 'ProductState' })
        },
        goCategories() {
            this.$router.push({ name: 'ProductCategory' })
        },
        goToReport(id) {
            var self = this || {}
            if (this.checkPermission('/product/idProd')) {
                this.form = true
                this.productID = id
            } else {
                self.$snackbar.open({
                    message: self.$t('product.no_permission'),
                    duration: 5000
                })
            }
        },
        addProduct() {
            if (this.checkPermission('/product/add')) {
                this.form = true
                this.productID = false
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('product.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.productID = false
            this.$router.push({
                name: 'Product',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        load() {
            var self = this
            self.$overlay.show()
            var items = this.$store.getters['label/getLabels']()
            var filters = this.$store.getters['label/getFilterStatus']()
            var categories = this.$store.getters['label/getCategories']()
            self.selected = []

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '300px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            self.$store.dispatch('label/loadFilters', {}).then(function () {
                if (Object.values(categories).length === 0) {
                    self.$store.dispatch('label/loadPrinters', {}).then(function () {
                        self.$overlay.hide()
                    })
                }
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(4, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(4, 0, translationHeader)
                }
            }

            if (Object.keys(items).length > 0 && Object.keys(categories).length > 0 && Object.keys(filters).length > 0) {
                // self.$store
                //   .dispatch('label/loadLabels', { status: 1 })
                //   .then(function () {
                if (self.$route.params.id) {
                    self.goToReport(self.$route.params.id)
                } else if (self.$route.name == 'ExportProduct') {
                    self.exportPDF = true
                } else if (self.$route.name == 'AddProduct') {
                    self.addProduct()
                } else {
                    self.form = false
                    self.productID = false
                }

                self.$overlay.hide()
                //   })
            } else {
                self.$store.dispatch('label/loadFiltersProducts', {}).then(function () {
                    self.$store.dispatch('label/loadCategoriesForm', {}).then(function () {
                        if (self.$route.params.id) {
                            self.goToReport(self.$route.params.id)
                        } else if (self.$route.name == 'AddProduct') {
                            self.addProduct()
                        } else if (self.$route.name == 'ExportProduct') {
                            self.exportPDF = true
                        } else {
                            self.form = false
                            self.productID = false
                        }
                        self.$overlay.hide()
                        // })
                    })
                })
            }
        },

        doPrint() {
            this.$router.push({
                name: 'ExportProduct'
            })
        },

        closeExportProduct() {
            this.exportPDF = false
            this.form = false
            this.productID = false
            this.$router.push({
                name: 'Product',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('ProductsSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('ProductsSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    },
    watch: {
        $route(to, from) {
            // react to route changes...

            this.load()
        }
    }
}
</script>

<style lang="scss" scoped>
#more-download-btn {
    position: absolute;
    z-index: 44;
    right: 24px;
    margin-top: 5px;
}
.product {
    .product-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .images {
                    @include background($size: contain);
                    width: 40px;
                    height: 40px;
                    border-radius: 4px;
                    margin: 0 auto;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }

                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }

            .type {
                display: inline-block;
                .timer {
                    display: none;
                    vertical-align: middle;
                    @include background($image: img('menu_timer_inactive.svg'), $size: 20px);
                    width: 30px;
                    min-width: 25px;
                    height: 30px;
                    padding: 6px;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    cursor: pointer;

                    &:not(.active):hover {
                        transition: 0.3s;
                        @include bgHover($color-primary-100);
                        @include background($image: img('menu_timer_active.svg'), $size: 20px);
                    }

                    &.active {
                        display: inline-block;
                        @include background($image: img('menu_timer_active.svg'), $size: 20px);
                    }
                }

                .label {
                    @include background($image: img('label_inactive.svg'), $size: 20px);
                    width: 30px;
                    min-width: 25px;
                    height: 30px;
                    padding: 6px;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    cursor: pointer;
                    display: none;
                    vertical-align: middle;

                    &:not(.active):hover {
                        transition: 0.3s;
                        @include bgHover($color-primary-100);
                        @include background($image: img('Label-active.svg'), $size: 20px);
                    }

                    &.active {
                        display: inline-block;
                        @include background($image: img('Label-active.svg'), $size: 20px);
                    }
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
