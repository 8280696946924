<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: JSON.stringify(currentFilters.name) != JSON.stringify(activeFilters.name)
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE CODE -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.filter') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.code && Object.values(currentFilters.code).length > 0,
                        changed: JSON.stringify(currentFilters.code) != JSON.stringify(activeFilters.code)
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.code"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE Category -->
            <div class="filter">
                <label>{{ $t('product.category') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.category && Object.values(currentFilters.category).length > 0,
                        changed: JSON.stringify(currentFilters.category) != JSON.stringify(activeFilters.category)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.category"
                    :items="Object.values(optionsCategories)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.category" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- TYPE STATE -->
            <div class="filter">
                <label>{{ $t('product.assigned_states') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.state && Object.values(currentFilters.state).length > 0,
                        changed: JSON.stringify(currentFilters.state) != JSON.stringify(activeFilters.state)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.state"
                    :items="Object.values(optionsStates)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.state" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- TYPE SELECT SITE OR TERRITORIES -->
            <div class="filter">
                <label>{{ $t('user.filters.search_by') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.sites,
                        changed: JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.sites"
                    v-if="Object.values(optionsSites)"
                    :items="Object.values(optionsSites)"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    @change="refreshSearch()"
                ></v-select>

                <div class="locations" v-if="currentFilters.sites == 'locations'">
                    <label class="description">{{ $t('user.filters.available_sites') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered:
                                currentFilters.locations &&
                                typeof currentFilters.locations !== 'undefined' &&
                                Object.values(currentFilters.locations).length > 0,
                            changed: JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.locations"
                        v-if="Object.values(optionsLocations)"
                        :items="Object.values(optionsLocations)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>
                    <Tags :tags="currentFilters.locations" :backImage="'location_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>
                <div class="custom" v-else-if="currentFilters.sites == 'groups'">
                    <label class="description">{{ $t('user.filters.available_territories') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered: currentFilters.groups && typeof currentFilters.groups !== 'undefined' && Object.values(currentFilters.groups).length > 0,
                            changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.groups"
                        v-if="Object.values(optionsGroups)"
                        :items="Object.values(optionsGroups)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>
                    <Tags :tags="currentFilters.groups" :backImage="'menu_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>
            </div>
            <!-- TYPE STATUS -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.state') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.status && Object.values(currentFilters.status).length > 0,
                        changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.status"
                    :items="Object.values(optionsStatus)"
                    :placeholder="$t('assets.filter.filter_by')"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
            <!-- TYPE FILTER -->
            <div v-if="toolTypeAvaible" class="filter">
                <label>{{ $t('timers_product.title') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.types && Object.values(currentFilters.types).length > 0,
                        changed: JSON.stringify(currentFilters.types) != JSON.stringify(activeFilters.types)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.types"
                    :items="Object.values(typesFilters)"
                    :placeholder="$t('assets.filter.filter_by')"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.types" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <FilterActions
            :showClearFiltersButton="showClearFiltersButton"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterProduct',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false },
        toolTypeAvaible: {type: Boolean, default: false}
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                status: [{ id: 1, name: i18n.t('tools.groups.status.active') }]
            },
            activeFilters: {},

            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true,
            typesFilters: [
                {
                    id: 1,
                    name: i18n.t('timers_product.label')
                },
                {
                    id: 2,
                    name: i18n.t('timers_product.digitalTimer')
                },
                {
                    id: 3,
                    name: i18n.t('timers_product.both')
                }
            ]
        }
    },
    computed: {
        optionsStatus() {
            return this.$store.getters['label/getFilterStatus']()
        },
        optionsStates() {
            return this.$store.getters['label/getStatesForm']()
        },
        optionsCategories() {
            return this.$store.getters['label/getCategoriesForm']()
        },
        optionsGroups() {
            return this.$store.getters['label/getFilterGroups']()
        },
        optionsLocations() {
            return this.$store.getters['label/getFilterLocations']()
        },
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                { value: 'groups', name: i18n.t('user.filters.territories') }
            ]
        },
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        refreshSearch() {
            delete this.currentFilters.locations
            delete this.currentFilters.groups
        },

        applyFilters() {
            this.$overlay.loading()
            var definitiveFilters = {}
            var locations = []
            var groups = []

            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if (this.currentFilters.code) {
                definitiveFilters.code = this.currentFilters.code
            } else definitiveFilters.code = ''

            if (this.currentFilters.state && Object.values(this.currentFilters.state).length) {
                var filter = this.currentFilters.state
                definitiveFilters.state = []
                for (var index in filter) {
                    definitiveFilters.state.push(filter[index].id)
                }
                definitiveFilters.state = definitiveFilters.state.toString()
            } else definitiveFilters.state = ''

            if (this.currentFilters.category) {
                var filter = this.currentFilters.category
                definitiveFilters.category = []
                for (var index in filter) {
                    definitiveFilters.category.push(filter[index].id)
                }
                definitiveFilters.category = definitiveFilters.category.toString()
            } else {
                definitiveFilters.category = ''
            }

            if (this.currentFilters.groups) {
                var filter = this.currentFilters.groups
                for (var index in filter) {
                    groups.push(filter[index].id)
                }
                definitiveFilters.groups = groups
            } else {
                definitiveFilters.groups = ''
            }
            if (this.currentFilters.locations) {
                var filter = this.currentFilters.locations
                for (var index in filter) {
                    locations.push(filter[index].id)
                }
                definitiveFilters.locations = locations
            } else {
                definitiveFilters.locations = ''
            }

            if (this.currentFilters.status) {
                var filter = this.currentFilters.status
                definitiveFilters.status = []
                for (var index in filter) {
                    definitiveFilters.status.push(filter[index].id)
                }
                definitiveFilters.status = definitiveFilters.status.toString()
            } else {
                definitiveFilters.status = ''
            }

            if (this.currentFilters.types) {
                var filter = this.currentFilters.types
                definitiveFilters.types = []
                for (var index in filter) {
                    definitiveFilters.types.push(filter[index].id)
                }
                definitiveFilters.types = definitiveFilters.types.toString()
            } else {
                definitiveFilters.types = ''
            }

            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false

            this.$store.commit('label/setFiltersProductActive', this.currentFilters)
            this.$store
                .dispatch('label/loadLabels', {
                    name: definitiveFilters.name,
                    code: definitiveFilters.code,
                    category: definitiveFilters.category,
                    status: definitiveFilters.status,
                    states: definitiveFilters.state,
                    locations: definitiveFilters.locations,
                    groups: definitiveFilters.groups,
                    types: definitiveFilters.types
                })
                .then(function () {
                    self.activeFilters = { ...self.currentFilters }
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('label/setFiltersProductActive', this.currentFilters)

            this.changesDetected = false
            this.$store.dispatch('label/loadLabels', { status: 1 }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        var translated = this.$store.getters['label/getFiltersProductActive']
        for (var name in translated.status) {
            var index = translated.status[name]
            if (index.name) {
                index.name = i18n.t(index.name)
            }
        }
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                    this.changesDetected = true
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
